import { ScreenInfo, ScreenType } from './types';

export const legalNameAndPreferredNameSection = 'legalAndPreferredName';

export const screens: Partial<Record<ScreenType, ScreenInfo>> = {
	[ScreenType.LANDING]: {
		screenType: ScreenType.LANDING,
		screenName: 'personalinfo-title',
		screenTitle: 'personalinfo-title',
		link: '/employees/{employeeId}/profile',
	},
	[ScreenType.SECTION]: {
		screenType: ScreenType.SECTION,
		screenName: '',
		screenTitle: '',
	},
	[ScreenType.EDIT]: {
		screenType: ScreenType.EDIT,
		screenName: '',
		screenTitle: '',
	},
};

export const updatedAtoZScreenFlow: Partial<Record<ScreenType, ScreenInfo>> = {
	[ScreenType.LANDING]: {
		screenType: ScreenType.LANDING,
		screenName: 'personalinfo-atoz-link-title',
		screenTitle: 'personalinfo-atoz-link-title',
		link: '/profile',
	},
	[ScreenType.PERSONAL_INFO]: {
		screenType: ScreenType.PERSONAL_INFO,
		screenName: 'personalinfo-landing-title',
		screenTitle: 'personalinfo-landing-title',
		link: '/personal_information',
	},
	[ScreenType.SECTION]: {
		screenType: ScreenType.SECTION,
		screenName: '',
		screenTitle: '',
	},
	[ScreenType.EDIT]: {
		screenType: ScreenType.EDIT,
		screenName: '',
		screenTitle: '',
	},
};

export const initialRBScreenFlow: Partial<Record<ScreenType, ScreenInfo>> = {
	[ScreenType.RB_JOURNEY_BUILDER_LANDING]: {
		screenType: ScreenType.RB_JOURNEY_BUILDER_LANDING,
		screenName: '',
		screenTitle: '',
	},
	[ScreenType.RB_RESUME_BUILDER_LANDING]: {
		screenType: ScreenType.RB_RESUME_BUILDER_LANDING,
		screenName: '',
		screenTitle: '',
	},
};

export const updatedRBScreenFlow: Partial<Record<ScreenType, ScreenInfo>> = {
	[ScreenType.RB_JOURNEY_BUILDER_LANDING]: {
		screenType: ScreenType.RB_JOURNEY_BUILDER_LANDING,
		screenName: 'personalinfo-languages-rb-journey-builder',
		screenTitle: 'personalinfo-languages-rb-journey-builder',
		link: '/journey',
	},
	[ScreenType.RB_RESUME_BUILDER_LANDING]: {
		screenType: ScreenType.RB_RESUME_BUILDER_LANDING,
		screenName: 'personalinfo-languages-rb-resume-builder',
		screenTitle: 'personalinfo-languages-rb-resume-builder',
		link: '/journeyresumebuilder',
	},
};

/** TODO - This needs to be  */
export const updatedMLEScreenFlow: { [key in ScreenType]?: ScreenInfo } = {
	[ScreenType.LANDING]: {
		screenType: ScreenType.LANDING,
		screenName: 'personalinfo-atoz-link-title',
		screenTitle: 'personalinfo-atoz-link-title',
		link: '/profile',
	},
	[ScreenType.MY_LIFE_EVENTS_LANDING]: {
		screenType: ScreenType.MY_LIFE_EVENTS_LANDING,
		screenName: 'mle-landing-page-title',
		screenTitle: 'mle-landing-page-title',
		link: '/my-life-events',
	},
	[ScreenType.MY_LIFE_EVENTS_EVENT_PAGE]: {
		screenType: ScreenType.MY_LIFE_EVENTS_EVENT_PAGE,
		screenName: '',
		screenTitle: '',
		link: '',
	},
	[ScreenType.SECTION]: {
		screenType: ScreenType.SECTION,
		screenName: '',
		screenTitle: '',
	},
	[ScreenType.EDIT]: {
		screenType: ScreenType.EDIT,
		screenName: '',
		screenTitle: '',
	},
};

export const MLEEventTitleMap: Record<string, string> = {
	relationship_status_change: 'mle-relationship-status-change-title',
	rsc: 'mle-relationship-status-change-title',
	legal_name_change: 'mle-legal-name-change-title',
	lnc: 'mle-legal-name-change-title',
	moving_homes: 'mle-moving-homes-title',
	mh: 'mle-moving-homes-title',
	gender_change: 'mle-gender-change-title',
	gc: 'mle-gender-change-title',
	death_of_loved_one: 'mle-death-of-loved-one-title',
	dlo: 'mle-death-of-loved-one-title',
};

export const MY_HR_FAB_ENDPOINT_BETA = 'https://atoz-livehelp-app.integ.amazon.work';
export const MY_HR_FAB_ENDPOINT = 'https://atoz-livehelp-app.amazon.work';
export const MY_HR_FAB_FEATURE_GATE = 'personal_information_myhr_fab';
export const MARITAL_STATUS_LIFE_EVENT_FEATURE_GATE = 'personal_information_app_marital_mle';
export const PID_AUTOMATION_BIRTH_INFO_FEATURE_GATE = 'personal_information_pid_automation_birth';
export const MY_LIFE_EVENTS_SUCCESS_BANNER_FEATURE_GATE = 'mle_updated_banners';
export const MY_LIFE_EVENTS_BOTTOM_BANNER_FEATURE_GATE = 'mle_bottom_banner';
export const MY_LIFE_EVENTS_P1_FEATURE_GATE = 'my_life_events_p1';
export const MY_LIFE_EVENTS_SUGGESTED_PAGE_FEATURE_GATE = 'my_life_events_suggested_page';
export const BIRTH_TICKET_EVENT = 'dob';
export const DVAS_PENDING_STATUS = 'in_progress';
export const DVAS_FAILED_STATUS = 'failed';
export const MARITAL_STATUS_ROUTE = 'maritalstatus';
export const ADDRESS_ROUTE = 'addresses';
export const NAME_CHANGE_ROUTE = 'legalandpreferredname';
export const GENDER_CHANGE_ROUTE = 'legalgendermedicaldisability';
export const BIRTH_INFO_ROUTE = 'birthinfo';
export const MLE_LANDING_PAGE_URL = '/my-life-events';
export const MLE_RELATIONSHIP_CHANGE_URL = '/my-life-events/relationship-status-change';
export const MLE_MOVING_HOMES_URL = '/my-life-events/moving-homes';
export const MLE_LEGAL_NAME_CHANGE_URL = '/my-life-events/legal-name-change';
export const MLE_GENDER_CHANGE_URL = '/my-life-events/gender-change';
export const MLE_RELATIONSHIP_CHANGE_SHORT_URL = '/my-life-events/rsc';
export const MLE_MOVING_HOMES_SHORT_URL = '/my-life-events/mh';
export const MLE_LEGAL_NAME_CHANGE_SHORT_URL = '/my-life-events/lnc';
export const MLE_GENDER_CHANGE_SHORT_URL = '/my-life-events/gc';
export const MLE_SUGGESTED_PAGE_URL = '/my-life-events/suggested-life-changes';
export const MLE_SUGGEST_ATTRIBUTE_PARAM = '?suggestedAttribute=';
export const MLE_SUGGEST_ATTRIBUTE_NAMES = 'lnpn';
export const ATOZ_PROFILE_FLOW_FEATURE_GATE = 'profile_web_app';
export const NEW_VISA_SCHEMA_KEY = 'newVisa';
export const NEW_PASSPORT_SCHEMA_KEY = 'newPassport';
export const NEW_EMAIL_KEY = 'newEmail';
export const NEW_PHONE_KEY = 'newPhone';
export const NEW_ADDRESS_KEY = 'newAddress';
export const NEW_NATIONAL_ID_KEY = 'newNationalId';

export const sectionsMap = [
	{
		apiModelKey: 'names',
		uiModelKey: 'legalAndPreferredName',
		sectionName: 'names',
		uiRoute: 'names',
		popstarFeatureId: 'personal_information_app_names',
	},
	{
		apiModelKey: 'pronoun',
		uiModelKey: 'pronouns',
		sectionName: 'pronouns',
		uiRoute: 'pronouns',
		popstarFeatureId: 'personal_information_app_pronouns',
	},
	{
		apiModelKey: 'birth',
		uiModelKey: 'birthInfo',
		sectionName: 'birth information',
		uiRoute: 'birthinfo',
		popstarFeatureId: 'personal_information_app_birth',
	},
	{
		apiModelKey: 'marital',
		uiModelKey: 'maritalStatus',
		sectionName: 'marital status information',
		uiRoute: 'maritalstatus',
		popstarFeatureId: 'personal_information_app_marital',
	},
	{
		apiModelKey: 'work_authorization',
		uiModelKey: 'workAuthorization',
		sectionName: 'work authorization',
		uiRoute: 'workauthorization',
		popstarFeatureId: 'personal_information_app_work_authorization',
	},
	{
		apiModelKey: 'contact_information',
		uiModelKey: 'contactInformation',
		sectionName: 'contact information',
		uiRoute: 'contactinfo',
		popstarFeatureId: 'personal_information_app_contact_info',
	},
	{
		apiModelKey: 'addresses',
		uiModelKey: 'addresses',
		sectionName: 'addresses',
		uiRoute: 'addresses',
		popstarFeatureId: 'personal_information_app_addresses',
	},
	{
		apiModelKey: 'gender_information_identity',
		uiModelKey: 'gender',
		sectionName: 'self identification - gender',
		uiRoute: 'gender',
		popstarFeatureId: 'personal_information_app_self_identification_gender',
	},
	{
		apiModelKey: 'ethnicity',
		uiModelKey: 'ethnicity',
		sectionName: 'self identification - ethnicity',
		uiRoute: 'ethnicity',
		popstarFeatureId: 'personal_information_app_self_identification_ethnicity',
	},
	{
		apiModelKey: 'military_veteran',
		uiModelKey: 'military',
		sectionName: 'self identification - military',
		uiRoute: 'military',
		popstarFeatureId: 'personal_information_app_self_identification_military',
	},
	{
		apiModelKey: 'disability_identity',
		uiModelKey: 'disability',
		sectionName: 'self identification - disability',
		uiRoute: 'disability',
		popstarFeatureId: 'personal_information_app_self_identification_disability',
	},
	{
		apiModelKey: 'education',
		uiModelKey: 'education',
		sectionName: 'education',
		uiRoute: 'education',
		popstarFeatureId: 'personal_information_app_education_info',
	},
	{
		apiModelKey: 'legal_gender_and_disability',
		uiModelKey: 'legalGenderMedicalDisability',
		sectionName: 'legal gender medical disability',
		uiRoute: 'genderdisability',
		popstarFeatureId: 'personal_information_app_legal_gender_and_medical_disability',
	},
	{
		apiModelKey: 'national_id',
		uiModelKey: 'nationalIds',
		sectionName: 'national ids',
		uiRoute: 'nationalid',
		popstarFeatureId: 'personal_information_app_national_id',
	},
	{
		apiModelKey: 'emergency_contact',
		uiModelKey: 'emergencyContacts',
		sectionName: 'emergency contacts',
		uiRoute: 'emergencycontacts',
		popstarFeatureId: 'personal_information_app_emergency_contacts',
	},
	{
		apiModelKey: 'other_id',
		uiModelKey: 'otherId',
		sectionName: 'other id',
		uiRoute: 'otherid',
		popstarFeatureId: 'personal_information_app_other_id',
	},
	{
		apiModelKey: 'language',
		uiModelKey: 'languages',
		sectionName: 'languages',
		uiRoute: 'languages',
		popstarFeatureId: 'personal_information_app_languages',
	},
	{
		apiModelKey: 'height',
		uiModelKey: 'height',
		sectionName: 'height',
		uiRoute: 'height',
		popstarFeatureId: 'personal_information_app_height',
	},
	{
		apiModelKey: 'legal_gender',
		uiModelKey: 'legalGender',
		sectionName: 'legal gender',
		uiRoute: 'legalgender',
		popstarFeatureId: 'personal_information_app_legal_gender',
	},
	{
		apiModelKey: 'medical_disability',
		uiModelKey: 'medicalDisability',
		sectionName: 'medical disability',
		uiRoute: 'medicaldisability',
		popstarFeatureId: 'personal_information_app_medical_disability',
	},
];

export enum WorkAuthSections {
	PASSPORT = 'passport',
	VISA = 'visa',
	DEEMED_EXPORT = 'deemedExports',
}

/** Picked up this list from PIC. These countries will require us to form countrySpecific object.
 * Will need to update the sections as we encounter */
export const countriesWithSpecificFields: Record<string, string[]> = {
	DEU: [],
	ITA: [],
	IND: [],
	SGP: [],
	PHL: [],
	CHN: [WorkAuthSections.VISA],
	MAR: [],
	GBR: [],
	FRA: [],
	ESP: [],
	IRL: [],
	POL: [],
	ROU: [],
	LUX: [],
	CRI: [],
	AUS: [],
	ZAF: [],
	CZE: [],
	SVK: [],
	COL: [],
	CAN: [],
};

export const homeAddressFileUploadCountries: Array<string> = ['CZE', 'DEU', 'ITA', 'MAR', 'ROU', 'POL', 'SVK'];

export const FILE_UPLOAD_KEY = 'fileupload';

export const extraErrors: Record<string, Record<string, any>> = {};

export const nonTrimAddressFields = [
	'country',
	'type',
	'effectiveDate',
	'addressCombined',
	'key',
	'sameAsContactAddress',
];

export const nonTrimEmergencyContactsFields = [
	'isPrimary',
	'name',
	'relationship',
	'phoneType',
	'sameAsContactPhone',
	'phoneNumer',
	'countryCode',
	'extension',
	'isPreferred',
	'addressType',
];

export const nonTrimPassportFields = ['country', 'fileUpload'];

export const nonTrimVisaFields = ['country', 'fileUpload'];

export const defaultEmergencyContactsChangeObjects = ['emergencycontactaddress_type', 'newemergencycontact'];

export const MAX_EMERGENCY_CONTACTS = 4;

export const POPSTAR_FEATURE_CONTRACTOR = 'personal_information_app_contingent_worker_optimization';

export const POPSTAR_FEATURE_ALUMNI = 'alumni';

export const PERSONAL_INFORMATION_ROOT = 'atoz-personal-information-app-root';
export const APP_ROOT = document.getElementById(PERSONAL_INFORMATION_ROOT);

export const NATIONALITY_COUNTRIES = ['ZAF'];

export const MLE_BOTTOM_BANNER_CONFIG = [
	{
		popstarId: MARITAL_STATUS_LIFE_EVENT_FEATURE_GATE,
		route: MARITAL_STATUS_ROUTE,
		redirectLink: {
			longUrl: MLE_RELATIONSHIP_CHANGE_URL,
			shortUrl: MLE_RELATIONSHIP_CHANGE_SHORT_URL,
		},
		bannerTitle: 'mle-marital-bottom-banner-title',
		bannerText: 'mle-marital-bottom-banner-text',
	},
	{
		popstarId: MY_LIFE_EVENTS_BOTTOM_BANNER_FEATURE_GATE,
		route: ADDRESS_ROUTE,
		redirectLink: {
			longUrl: MLE_MOVING_HOMES_URL,
			shortUrl: MLE_MOVING_HOMES_SHORT_URL,
		},
		bannerTitle: 'mle-addresses-bottom-banner-title',
		bannerText:
			'mle-marital-bottom-banner-text' /* TODO - Though the strings are the same, we need will `mle-bottom-banner-text` for better naming. That doesnt have period so using that for now*/,
	},
	{
		popstarId: MY_LIFE_EVENTS_BOTTOM_BANNER_FEATURE_GATE,
		route: NAME_CHANGE_ROUTE,
		redirectLink: {
			longUrl: MLE_LEGAL_NAME_CHANGE_URL,
			shortUrl: MLE_LEGAL_NAME_CHANGE_SHORT_URL,
			suggestedUrl: MLE_SUGGESTED_PAGE_URL,
		},
		bannerTitle: 'mle-names-bottom-banner-title',
		bannerText:
			'mle-marital-bottom-banner-text' /* TODO - Though the strings are the same, we need will `mle-bottom-banner-text` for better naming. That doesnt have period so using that for now*/,
	},
	{
		popstarId: MY_LIFE_EVENTS_BOTTOM_BANNER_FEATURE_GATE,
		route: GENDER_CHANGE_ROUTE,
		redirectLink: {
			longUrl: MLE_GENDER_CHANGE_URL,
			shortUrl: MLE_GENDER_CHANGE_SHORT_URL,
		},
		bannerTitle: 'mle-gender-bottom-banner-title',
		bannerText:
			'mle-marital-bottom-banner-text' /* TODO - Though the strings are the same, we need will `mle-bottom-banner-text` for better naming. That doesnt have period so using that for now*/,
	},
];

export const MLE_SUGGEST_ROUTE_MAP = new Map<string, string>([[NAME_CHANGE_ROUTE, MLE_SUGGEST_ATTRIBUTE_NAMES]]);
