import { NameCharacterType, NameType, EmailType, AddressType } from 'src/types/PersonalDetails';
import { PhoneType } from '@amzn/esspiappconfig';

export const namesTypeMap: Record<string, Record<string, string>> = {
	legalName: { type: NameType.Primary, characterType: NameCharacterType.Default, htmlLanguage: 'default' },
	kanjiName: { type: NameType.Primary, characterType: NameCharacterType.JPN_KANJI, htmlLanguage: 'ja-Hani' },
	katakanaName: { type: NameType.Primary, characterType: NameCharacterType.JPN_KATAKANA, htmlLanguage: 'ja-Hrkt' },
	pinyinName: { type: NameType.Primary, characterType: NameCharacterType.CHN_PINYIN, htmlLanguage: 'zh-pinyin' },
	filipinoName: { type: NameType.Primary, characterType: NameCharacterType.PHL, htmlLanguage: 'fil' },
	polishName: { type: NameType.Primary, characterType: NameCharacterType.POL, htmlLanguage: 'pl' },
	simplifiedChineseName: { type: NameType.Primary, characterType: NameCharacterType.CHN, htmlLanguage: 'zh-Hans' },
	koreanName: { type: NameType.Primary, characterType: NameCharacterType.KOR, htmlLanguage: 'ko' },
	preferredName: { type: NameType.Preferred, characterType: NameCharacterType.Default, htmlLanguage: 'default' },
	preferredKanjiName: { type: NameType.Preferred, characterType: NameCharacterType.JPN_KANJI, htmlLanguage: 'ja-Hani' },
	preferredKatakanaName: {
		type: NameType.Preferred,
		characterType: NameCharacterType.JPN_KATAKANA,
		htmlLanguage: 'ja-Hrkt',
	},
	preferredPinyinName: {
		type: NameType.Preferred,
		characterType: NameCharacterType.CHN_PINYIN,
		htmlLanguage: 'zh-pinyin',
	},
	preferredFilipinoName: { type: NameType.Preferred, characterType: NameCharacterType.PHL, htmlLanguage: 'fil' },
	preferredPolishName: { type: NameType.Preferred, characterType: NameCharacterType.POL, htmlLanguage: 'pl' },
	preferredSimplifiedChineseName: {
		type: NameType.Preferred,
		characterType: NameCharacterType.CHN,
		htmlLanguage: 'zh-Hans',
	},
	preferredKoreanName: { type: NameType.Preferred, characterType: NameCharacterType.KOR, htmlLanguage: 'ko' },
	newLocalName: { type: NameType.Primary },
	newPreferredLocalName: { type: NameType.Preferred },
};

export const emailsTypeMap: Record<string, Record<string, string>> = {
	preferredInternalEmail: { type: EmailType.PreferredInternal },
	primaryInternalEmail: { type: EmailType.PrimaryInternal },
	personalEmail: { type: EmailType.Personal },
	otherEmail: { type: EmailType.Other },
	pagerEmail: { type: EmailType.Pager },
};

export const phonesTypeMap: Record<string, Record<string, string>> = {
	homePhone: { type: PhoneType.Home },
	mobilePhone: { type: PhoneType.Mobile },
	otherPhone: { type: PhoneType.Other },
};

export const addressTypeMap: Record<string, Record<string, string>> = {
	homeAddress: { type: AddressType.Home, futureAddressTitle: 'personalinfo-future-homeaddress-title' },
	mailingAddress: { type: AddressType.Mailing, futureAddressTitle: 'personalinfo-future-mailingaddress-title' },
	permanentAddress: { type: AddressType.Permanent, futureAddressTitle: 'personalinfo-future-permanentaddress-title' },
};

export interface FormWorkAuthorization {
	[key: string]: FormVisa | FormPassport | FormDeemedExports;
}

export interface FormLegalAndPreferredName {
	legalName?: FormName;
	preferredName?: FormName;
	pinyinName?: FormName;
	kanjiName?: FormName;
	katakanaName?: FormName;
	filipinoName?: FormName;
	polishName?: FormName;
	simplifiedChineseName?: FormName;
	koreanName?: FormName;
	preferredPinyinName?: FormName;
	preferredKanjiName?: FormName;
	preferredKatakanaName?: FormName;
	preferredFilipinoName?: FormName;
	preferredPolishName?: FormName;
	preferredSimplifiedChineseName?: FormName;
	preferredKoreanName?: FormName;
	newPreferredLocalName?: FormName;
	newLocalName?: FormName;
}

export interface FormName {
	type?: NameType;
	nameCombined?: string;
	firstName?: string;
	middleName?: string;
	lastName?: string;
	secondLastName?: string;
	nameCharacterType?: NameCharacterType;
	secondaryLastName?: string;
	maidenName?: string;
	title?: string;
	suffix?: string;
	fileUpload?: Record<string, string>;
}

export interface FormGender {
	gender?: string;
	freeTextGenderOption?: string;
	genderOptOut?: boolean;
	genderCombined?: string;
	transgenderOptOut?: boolean;
	transgender?: string;
	sexualOrientationOptOut?: boolean;
	sexualOrientation?: string;
}

export interface FormSelfIdentification {
	gender?: FormGender;
}

export interface FormBirthInfo {
	dateOfBirth?: string;
	place?: string;
	postalCode?: string;
	country?: string;
}

export interface FormMaritalStatus {
	status?: string;
	statusDate?: string;
}

export interface FormVisa {
	type?: string;
	country?: string;
	issueDate?: string;
	id?: string;
	expirationDate?: string;
	macaoNumber?: string;
	hongkongNumber?: string;
	taiwanNumber?: string;
	visaCombined?: string;
}

export interface FormPassport {
	key?: string;
	type?: string;
	country?: string;
	issuedBy?: string;
	issueDate?: string;
	id?: string;
	expirationDate?: string;
	passportCombined?: string;
}

export interface FormDeemedExports {
	isUsCitizen?: string;
	country?: string;
	status?: string;
	issueDate?: string;
	deemedExportsCombined?: string;
	citizenshipInfo?: CitizenshipInfo;
}

export interface CitizenshipInfo {
	country?: string;
	status?: string;
	issueDate?: string;
}

export interface FormPhones {
	[key: string]: FormPhone;
}

export interface FormPhone {
	key?: string;
	phoneNumberCombined?: string;
	phoneNumber?: string;
	countryCode?: string;
	extension?: string;
	isPreferred?: boolean;
	isPublic?: boolean;
	type?: PhoneType;
	sameAsContactPhone?: boolean;
}

export interface FormEmails {
	preferredInternalEmail?: FormEmail;
	primaryInternalEmail?: FormEmail;
	personalEmail?: FormEmail;
	otherEmail?: FormEmail;
	pagerEmail?: FormEmail;
	newEmail?: FormEmail;
}

export interface FormEmail {
	type?: EmailType;
	address?: string;
}

export interface FormContactInformation {
	phones?: FormPhones;
	emails?: FormEmails;
}

export interface FormAddress {
	key?: string;
	type?: string;
	addressCombined?: string;
	sameAsContactAddress?: boolean;
	effectiveDate?: string;
	country?: string;
	addressLine1?: string;
	addressLine2?: string;
	addressLine3?: string;
	addressLine4?: string;
	addressLine5?: string;
	addressLine6?: string;
	addressLine7?: string;
	addressLineLocal1?: string;
	addressLineLocal2?: string;
	city?: string;
	state?: string;
	stateDescription?: string;
	streetCode?: string;
	streetCodeDescription?: string;
	county?: string;
	postalCode?: string;
	fileUpload?: Record<string, string>;
}

export interface FormAddresses {
	homeAddress?: FormAddress;
	homeAddress1?: FormAddress;
	mailingAddress?: FormAddress;
	mailingAddress1?: FormAddress;
	permanentAddress?: FormAddress;
	permanentAddress1?: FormAddress;
	newAddress?: FormAddress;
}

export interface FormMilitary {
	veteranStatusOptOut?: boolean;
	veteranStatus?: string;
	reserveForceStatusOptOut?: boolean;
	reserveForceStatus?: string;
	militarySpouseStatusOptOut?: boolean;
	militarySpouseStatus?: string;
	protectedVeteranStatus?: string;
}

export interface FormDisability {
	disabilityOptOut?: boolean;
	disabilityStatus?: string;
}

export interface FormEthnicity {
	race?: FormRace;
	indigenous?: FormIndigenous;
}

export interface FormRace {
	raceOptOut?: boolean;
	raceAndEthnicity?: string;
	nationality?: string;
}

export interface FormIndigenous {
	indigenousPerson?: string;
	indigenousOptOut?: boolean;
	minority?: string;
	minorityOptOut?: boolean;
}

export interface FormEducation {
	highestEducationLevel?: string;
}

export interface FormNationalIds {
	[key: string]: FormNationalId;
}

export interface FormEmergencyContact {
	key?: string;
	titleCombined?: string;
	emergencyContactDetails: {
		name?: string;
		relationship?: string;
		isPrimary?: boolean;
	};
	emergencyContactPhones?: FormPhones & {
		[key: string]: FormPhone;
	};
	emergencyContactAddress?: FormAddress;
}

type NewEmergencyContactType = FormAddress & FormPhone;
export interface NewEmergencyContact extends NewEmergencyContactType {
	key?: string;
	name?: string;
	relationship?: string;
	isPrimary?: boolean;
	phoneType?: string;
	addressType?: string;
}
export interface FormEmergencyContacts {
	emergencyContact1?: FormEmergencyContact;
	emergencyContact2?: FormEmergencyContact;
	emergencyContact3?: FormEmergencyContact;
	emergencyContact4?: FormEmergencyContact;
	newEmergencyContact?: NewEmergencyContact;
}

export interface FormNationalId {
	type?: string;
	country?: string;
	id?: string;
	ssnKey?: string;
	expirationDate?: string;
	isPrimary?: boolean;
	isTaxReferenceId?: boolean;
	fileUpload?: Record<string, string>;
}

export interface FormOtherId {
	nidLastFour?: string;
}

export interface FormLegalGenderMedicalDisability {
	legalGender?: FormLegalGender;
	medicalDisability?: FormMedicalDisability;
	height?: FormHeight;
}

export interface FormLegalGender {
	gender?: string;
}

export interface FormMedicalDisability {
	disability?: boolean;
	disclosureDate?: string;
	startDate?: string;
	type?: string;
}

export interface FormHeight {
	heightCombined?: string;
	heightOptIn?: string;
	feet?: string;
	inches?: string;
	centimeters?: string;
}

export interface FormPronouns {
	pronouns?: string;
	pronounsOther?: string;
	pronounsCombined?: string;
}

export interface FormLanguages {
	[key: string]: FormLanguage;
}

export interface FormLanguage {
	title?: string;
	proficient?: string;
	preferred?: boolean;
	native?: boolean;
	speaking?: string;
	reading?: string;
	writing?: string;
	abilities?: string;
}

export interface FormData {
	legalAndPreferredName?: FormLegalAndPreferredName;
	selfIdentification?: FormSelfIdentification;
	birthInfo?: FormBirthInfo;
	workAuthorization?: FormWorkAuthorization;
	contactInformation?: FormContactInformation;
	addresses?: FormAddresses;
	military?: FormMilitary;
	disability?: FormDisability;
	ethnicity?: FormEthnicity;
	nationalIds?: FormNationalIds;
	legalGenderMedicalDisability?: FormLegalGenderMedicalDisability;
	emergencyContacts?: FormEmergencyContacts;
	pronouns?: FormPronouns;
	otherId?: FormOtherId;
	languages?: FormLanguages;
}
