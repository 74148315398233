import { Metric } from 'web-vitals';

export type UrlParam = 'country' | 'useMockData';

export type ScreenInfo = {
	screenType: string;
	screenName: string;
	screenTitle: string;
	link?: string;
};

export enum ScreenType {
	LANDING = 'LANDING',
	PERSONAL_INFO = 'PERSONAL_INFO',
	MY_LIFE_EVENTS_LANDING = 'MY_LIFE_EVENTS_LANDING',
	MY_LIFE_EVENTS_EVENT_PAGE = 'MY_LIFE_EVENTS_EVENT_PAGE',
	RB_JOURNEY_BUILDER_LANDING = 'RB_JOURNEY_BUILDER_LANDING',
	RB_RESUME_BUILDER_LANDING = 'RB_RESUME_BUILDER_LANDING',
	SECTION = 'SECTION',
	EDIT = 'EDIT',
}

export enum METRIC_UNIT {
	COUNT = 'Count',
	MILLISECONDS = 'Milliseconds',
	NONE = 'None',
}

export enum METRIC_NAME {
	APP_ERROR_UNKNOWN = 'app.error.unknown',
	PAGE_VIEW = 'page.views',
	PAGE_VIEW_FROM_MLE = 'source_mle.page.views',
	PAGE_VIEW_FROM_RB = 'source_rb.page.views',
	APP_LATENCY_CF = 'app.latency.cf',
	FORM_SUBMISSION_COUNT = 'ess.form.submit',
	FORM_SUBMISSION_COUNT_FROM_MLE = 'source_mle.form.submit',
	ASYNC_FORM_SUBMISSION_COUNT_FROM_MLE = 'source_mle.async.form.submit',
	FORM_SUBMISSION_COUNT_FROM_RB = 'source_rb.form.submit',
	FORM_DELETION = 'ess.form.delete',
	FORM_DELETION_FAILED = 'ess.form.deletefailed',
	FORM_DELETION_FROM_RB = 'source_rb.form.delete',
	ADDRESS_AUTOFILL = 'ess.form.address_autofill',
	ADDRESS_AUTOFILL_OVERRIDE = 'ess.form.address_autofill_override',
	ADDRESS_AUTOFILL_ACCEPTED = 'ess.form.address_autofill_accepted',
	FAB_ERRORS = 'fab.errors',
	MLE_BOTTOM_BANNER_CLICK = 'mle.bottom_banner.click',
	MLE_ADDITIONAL_CHANGES_LINK_CLICK = 'mle.additional_changes_link.click',
	MLE_SUCCESS_BANNER_LINK_CLICK = 'mle.success_banner_link.click',
	RB_BANNER_LINK_CLICK = 'source_rb.banner_link.click',
}

export enum DIMENSION_NAME {
	PAGE_NAME = 'PageName',
	DURATION = 'duration',
	COUNTRY_CODE = 'countryCode',
	ENTITY = 'entity',
}

export type DIMENSION_VALUES = {
	[key in DIMENSION_NAME]?: any;
};

export type EMFMetric = {
	_aws: {
		Timestamp: number;
		CloudWatchMetrics: {
			Namespace: string;
			Metrics: { Name: string; Unit?: string }[];
		}[];
	};
};

export type MetaDataMetric = {
	metricName: string | Metric['name'];
	metricValue: number;
	metricUnit: METRIC_UNIT;
	dimensions?: DIMENSION_VALUES;
	namespace?: string;
};

export interface UIError extends Error {
	code?: string;
}
