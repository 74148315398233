import { PhoneType, PhoneVisibility } from '@amzn/esspiappconfig';

export interface PersonalDetails {
	names?: Name[];
	addresses?: Address[];
	emails?: Email[];
	phones?: Phone[];
	birth?: BirthInfo;
	genderInformationIdentity?: GenderIdentity;
	legalGender?: LegalGender;
	military?: Military;
	veteran?: Veteran;
	ethnicity?: Ethnicity;
	disability?: MedicalDisability;
	disabilityIdentity?: IdentityDisabilityInfo;
	education?: EducationInfo;
	marital?: MaritalStatus;
	visas?: Visa[];
	passports?: Passport[];
	deemedExportCitizenship?: DeemedExports;
	nationalIds?: NationalId[];
	otherId?: OtherId;
	citizenship?: Citizenship[];
	healthCare?: HealthCareInfo;
	dependents?: DependentInfo[];
	transportation?: TransportationInfo;
	emergencyContacts?: EmergencyContactInfo[];
	countrySpecific?: CountrySpecific;
	preference?: Preference;
	pronoun?: Pronoun;
	languages?: Language[];
	height?: Height;
}

export enum NameType {
	Primary = 'PRI',
	Preferred = 'PRF',
}

export enum NameCharacterType {
	CHN = 'CHN',
	CHN_PINYIN = 'CHN_PINYIN',
	JPN_KANJI = 'JPN_KANJI',
	JPN_KATAKANA = 'JPN_KATAKANA',
	PHL = 'PHL',
	POL = 'POL',
	KOR = 'KOR',
	Default = 'DEFAULT',
}

export const nationalIdTypesByLabel: Record<string, string> = {
	sss: 'PHL-NID',
	tax: 'PHL-TXID',
	hdmf: 'PHL-HDMF',
	philHealth: 'PHL-HID',
	ausTaxNumberEight: 'AUS-TFN_8',
	ausTaxNumberNine: 'AUS-TFN_9',
	birthNumber: 'CZE-BID',
	criNid9: 'CRI-PR',
	criNid17: 'CRI-PR2',
	criPerm: 'CRI-PR1',
	criSsn8: 'CRI-FSS-8',
	criSsn9: 'CRI-FSS-9',
	criSsn10: 'CRI-FSS-10',
	pps8: 'IRL-NIN',
	pps9: 'IRL-PPS',
	zafNid: 'ZAF-SA_NID',
};

export interface Name {
	type: NameType;
	nameCharacterType: NameCharacterType;
	nameDisplay?: string;
	prefix?: string;
	suffix?: string;
	firstName?: string;
	middleName?: string;
	lastName?: string;
	maidenName?: string;
	secondaryLastName?: string;
}

export interface Pronoun {
	pronouns?: string[];
	pronounsOther?: string;
	effectiveDate?: string;
	country?: string;
	isBackfilledFromPhoneTool?: boolean;
}

export enum AddressType {
	Home = 'Home',
	Mailing = 'Mailing',
	Permanent = 'Permanent',
}

export interface Address {
	key: string;
	type: AddressType;
	country: string;
	city: string;
	postalCode?: string;
	segment1: string;
	state?: string;
	streetCodeDescription?: string;
	stateDescription?: string;
	segment2?: string;
	segment3?: string;
	segment4?: string;
	segment5?: string;
	segment6?: string;
	segment7?: string;
	segmentLocal1?: string;
	segmentLocal2?: string;
	county?: string;
	deletedFlg?: boolean;
	effectiveDate?: string;
}

export enum EmailType {
	PrimaryInternal = 'PINT',
	PreferredInternal = 'PRIN',
	Personal = 'PERS',
	Other = 'OTHR',
	Pager = 'PGML',
}

export interface Email {
	type: EmailType;
	address: string;
}
export interface Phone {
	key: string;
	type: PhoneType;
	number: string;
	isPreferred: boolean;
	countryCode?: string;
	deletedFlg?: boolean;
	extension?: string;
	visibility?: PhoneVisibility;
}

export interface EducationInfo {
	highestEducationLevel: string;
	highestEducation?: EducationItem;
	secondHighestEducation?: EducationItem;
	thirdHighestEducation?: EducationItem;
	isFullTimeStudent?: boolean;
}

export interface EducationItem {
	major?: string;
	school?: string;
	degree?: string;
	gradDate?: string;
}

export interface BirthInfo {
	dateOfBirth: string;
	birthPlace?: BirthPlace;
}

export interface BirthPlace {
	country?: string;
	state?: string;
	postalCode?: string;
	place?: string;
	county?: string;
}

export interface MaritalStatus {
	status: string;
	statusDate?: string;
}

export interface OtherId {
	nidLastFour: string;
}

export interface NationalId {
	id: string;
	country: string;
	type: string;
	expirationDate?: string;
	isPrimary?: boolean;
	ssnKey?: string;
	isTaxReferenceId?: boolean;
}

export interface Visa {
	type: string;
	country: string;
	issueDate: string;
	id: string;
	expirationDate: string;
	mainlandTravelPermitForMacaoResidentsNumber?: string;
	mainlandTravelPermitForHongkongResidentsNumber?: string;
	mainlandTravelPermitForTaiwanResidentsNumber?: string;
}

export interface Passport {
	key: string;
	type: string;
	country: string;
	issuedBy: string;
	issueDate: string;
	id: string;
	expirationDate: string;
}

export interface DeemedExports {
	isUsCitizen: boolean;
	country: string;
	issueDate?: string | null;
	status: string | null;
}

export interface Citizenship {
	country: string;
	status?: string;
}

export interface HealthCareInfo {
	complimentaryHealthOption?: string;
	cpamCenterName?: string;
	cpamCenterAddress?: string;
	healthId?: string;
	healthInsuranceAgency?: string;
	insuranceName?: string;
	insuranceCompany?: string;
	insuranceHouseCounty?: string;
	hasHealthInsurance?: boolean;
	pensionFund?: string;
}

export interface DependentInfo {
	id: number;
	firstName: string;
	lastName: string;
	birthDate: string;
	nationalIds: NationalId[];
	isDisabled?: boolean;
	gender: string;
	relationship: string;
	isChildSocialSecurityDependent?: boolean;
	isChildTaxDependent?: boolean;
}

export interface TransportationInfo {
	usePublicTransitToWork: boolean;
	oneWayFeeTotal: string;
	monthlyCommutingPassTotal: string;
	transportationItems?: TransportationItem[];
	note?: string;
}

export enum TransportationType {
	BUS = 'BUS',
	TRAIN = 'TRAIN',
}

export interface TransportationItem {
	type: TransportationType;
	monthlyCommutingPassPrice: string;
	oneWayFee: string;
	line?: string;
	company?: string;
	from?: string;
	to?: string;
}

export interface EmergencyContactPhone {
	phone: Phone;
	phoneCopiedFromEmployee?: boolean;
}
export interface EmergencyContactAddress {
	address?: Address;
	addressCopiedFromEmployee?: boolean;
}

export interface EmergencyContactInfo {
	key: string;
	name: string;
	relationship: string;
	isPrimary: boolean;
	emergencyContactPhones?: EmergencyContactPhone[];
	emergencyContactAddresses?: EmergencyContactAddress[];
}

export interface Military {
	country?: string;
	isReserveForceMember?: string;
	isReserveForceMemberOptout?: boolean;
	militarySpouseStatus?: string;
	militarySpouseStatusOptout?: boolean;
}

export interface Veteran {
	country?: string;
	status?: string;
	veteranStatusOptout?: boolean;
	protectedVeteran?: string;
	veteranSupportOptout?: boolean;
	veteranSupport?: string;
}

export interface Ethnicity {
	country?: string;
	ethnicityOptout?: boolean;
	races?: Race[];
	nationality?: string;
}

export interface Race {
	type: string;
}

export interface GenderIdentity {
	genderIdentity: GenderInfo;
	transgender?: TransgenderInfo;
	sexualOrientation?: SexualOrientationInfo;
}

export interface GenderInfo {
	genderIdentity?: string;
	genderIdentityOptout?: boolean;
	genderIdentityOther?: string;
}

export interface SexualOrientationInfo {
	orientation?: string;
	sexualOrientationsOptout?: boolean;
}

export interface TransgenderInfo {
	isTransgender?: string;
	isTransgenderOptout?: boolean;
}

export interface IdentityDisabilityInfo {
	identityWithDisability?: string | null;
	identityWithDisabilityOptout?: boolean;
	effectiveDate?: string;
	country?: string;
}

export interface Preference {
	preferredLanguage: string;
	preferredPronouns: string;
}

export interface LegalGender {
	country?: string;
	gender?: string;
}

export interface MedicalDisability {
	country?: string;
	hasCurrentOrPastDisability: string;
	type?: string;
	disclosureDate?: string;
	startDate?: string;
}

export interface Height {
	optout?: boolean;
	unit?: string;
	valueInch?: number;
	valueCentimeter?: number;
}

export interface Language {
	key?: string;
	preferredLanguage?: boolean;
	proficientLanguage?: string;
	nativeLanguage?: boolean;
	translator?: boolean;
	teacher?: boolean;
	speakingProficiency?: string;
	readingProficiency?: string;
	writingProficiency?: string;
	effectiveStatus?: string;
}

export interface CountrySpecific {
	chn?: CountrySpecificCHN;
	can?: CountrySpecificCAN;
}

export interface CountrySpecificCHN {
	mainlandTravelPermitForHongkongResidentsNumber?: string | null;
	mainlandTravelPermitForMacaoResidentsNumber?: string | null;
	mainlandTravelPermitForTaiwanResidentsNumber?: string | null;
}

export interface CountrySpecificCAN {
	indigenousPerson: string;
	indigenousPersonOptout: boolean;
	visibleMinority: string;
	visibleMinorityOptout: boolean;
}
