import { isProdStage } from 'src/utils/stage';
import { MY_HR_FAB_ENDPOINT, MY_HR_FAB_ENDPOINT_BETA } from 'src/types';

if (isProdStage) {
	(window as any).FAB_URL = MY_HR_FAB_ENDPOINT;
} else {
	(window as any).FAB_URL = MY_HR_FAB_ENDPOINT_BETA;
}

import './public-path';
import('./bootstrap');
export {};
